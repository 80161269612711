import React, { useState } from "react";

import { Link } from "react-router-dom";
import HeaderNavbar from "../../component/HeaderNavbar";
import './Feedback.css'

const Feedback = () => {


  return (
    <div className="track-main">
      <HeaderNavbar />
      <div className="main-container my-5 p-2">
        <div className="feedback-container">
          <h2 className="feedback-title">Complaints & Feedback</h2>
          <p className="feedback-subtitle">
            Your feedback is important in improving our services.
          </p>
          <form className="feedback-form">
            <input
              type="text"
              className="form-input"
              placeholder="*Vendor Code"
              required
            />
            <input
              type="text"
              className="form-input"
              placeholder="*Vendor Name"
              required
            />
            <input
              type="text"
              className="form-input"
              placeholder="*Subject"
              required
            />
            <textarea
              className="form-textarea"
              placeholder="*Message"
              rows="4"
              required
            ></textarea>
            <button type="submit" className="form-submit">
              Submit
            </button>
          </form>
        </div>

      </div>

      <div className="footer">
        <p style={{ fontSize: "12px", color: "grey" }}>
          &copy; 2024, Emirates Logistics | Developed By{" "}
          <Link
            to="http://globuslabs.com"
            className="text-dark "
            target="_blank"
          >
            Globus Labs
          </Link>{" "}
          (Official IT Partner)
        </p>
      </div>
    </div>
  );
};

export default Feedback;
