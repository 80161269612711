import { configureStore } from "@reduxjs/toolkit";
import alertReducer from '../features/alertSlice';
import asnUploadReducer from "../features/asnSlice";
import grnUploadReducer from "../features/grnSlice";
import trackingStatusReducer from '../features/trackingStatusSlice';
import logger from "redux-logger";




export const store = configureStore({
  reducer: {
    alerts: alertReducer,
    asnUpload: asnUploadReducer,
    grnUpload: grnUploadReducer,
    trackingStatus: trackingStatusReducer,

  },


  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(logger),

  // devTools: process.env.NODE_ENV !== "production",
});

