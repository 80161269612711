// // src/auth/auth.js
// import { Navigate } from "react-router-dom";
// import Cookies from 'js-cookie';

// const auth = ({ children }) => {
//     const token = Cookies.get('authToken');
//     return token ? children : <Navigate to="/" replace />;
// };

// export default auth;











// src/auth/auth.js
import { Navigate } from "react-router-dom";

const Auth = ({ children }) => {
    const token = sessionStorage.getItem('token'); // Correct method to retrieve the token
    console.log(token);

    return token ? children : <Navigate to="/login" replace />;
};

export default Auth;
