
// // componnets/Alert.js

// import React from 'react';
// import { useSelector } from 'react-redux';

// const Alert = () => {
//   const alerts = useSelector((state) => state.alerts); 
  

//   return (
//     alerts !== null &&
//     alerts.length > 0 &&
//     alerts.map((alert) => (
//       <div
//         key={alert.id}
//         className={`alert alert-${alert.alertType} alert-dismissible fade show`}
//         role="alert"
//         style={{
//           position: "fixed",
//           top: "10px",
//           right: "10px",
//           zIndex: "9999",
//         }}
//       >
//         {alert.msg}
//         <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
//       </div>
//     ))
//   );
// };

// export default Alert;

// components/Alert.js


import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeAlert } from '../features/alertSlice'; 

const Alert = () => {
  const alerts = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  useEffect(() => {
    if (alerts.length === 0) return;

    const interval = setInterval(() => {
     
      if (alerts.length > 0) {
        dispatch(removeAlert(alerts[0].id));
      }
    }, 5000); 

    return () => clearInterval(interval); 
  }, [alerts, dispatch]);

  return (
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div
        key={alert.id}
        className={`alert alert-${alert.alertType} alert-dismissible fade show`}
        role="alert"
        style={{
          position: "fixed",
          top: `${10 + alerts.indexOf(alert) * 60}px`, 
          right: "10px",
          zIndex: "9999",
        }}
      >
        {alert.msg}
        <button
          type="button"
          className="btn-close"
          onClick={() => dispatch(removeAlert(alert.id))} 
          aria-label="Close"
        ></button>
      </div>
    ))
  );
};

export default Alert;
