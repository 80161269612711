
import logo from "../assets/emiratesLogo.png";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faInfoCircle,
  faCommentAlt,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css"; 

const HeaderNavbar = () => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "transparent", height: "70px" }}>
      <Container fluid className="d-flex justify-content-between">
        <NavLink to="/">
          <img
            src={logo}
            alt="logo"
            style={{
              height: "90px",
              objectFit: "cover",
              objectPosition: "center top",
              overflow: "hidden",
            }}
          />
        </NavLink>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          id="navbarScroll"
          className="navbar-collapse-custom" // Add a class
        >
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{
              maxHeight: "100px",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "small",
            }}
            navbarScroll
          >
            <NavLink
              to="/"
              style={{
                color: "#003c78",
                textDecoration: "none",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "small",
              }}
              className="nav-link"
            >
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: "2px" }}
              />{" "}
              Track
            </NavLink>
            <NavLink
              to="http://www.emirateslogistics.com/"
              target="_blank"
              style={{
                color: "#003c78",
                textDecoration: "none",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "small",
              }}
              className="nav-link"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ marginRight: "2px" }}
              />{" "}
              About
            </NavLink>
            <NavLink
              to="/feedback"
              style={{
                color: "#003c78",
                textDecoration: "none",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "small",
              }}
              className="nav-link"
            >
              <FontAwesomeIcon
                icon={faCommentAlt}
                style={{ marginRight: "2px" }}
              />{" "}
              Feedback
            </NavLink>
            <NavLink
              to="/login"
              style={{
                color: "#003c78",
                textDecoration: "none",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "small",
              }}
              className="nav-link"
            >
              <FontAwesomeIcon
                icon={faSignInAlt}
                style={{ marginRight: "2px" }}
              />{" "}
              Login
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;
